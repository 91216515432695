<template>
  <section class="container-fluid" id="about-aomori">
    <div class="container-L">
      <h2>ខេត្តអាអូម៉ូរី ប្រទេសជប៉ុន</h2>
      <div class="content-bottom">
        <div class="row">
          <img class="img-m" :src="require(`@/assets/${img.main.url}`)" :alt="img.main.alt">
          <div class="content-text">
            <p>ខេត្តអាអូម៉ូរី ស្ថិតនៅក្នុងតំបន់តូហុគឹ ដែលមានទីតាំងនៅភាគខាងជើងនៃប្រទេសជប៉ុន។ ជាមួយនឹងអាកាសធាតុត្រជាក់ពេញមួយឆ្នាំ ព្រិលក្រាស់ក្នុងរដូវរងា រដូវក្តៅក៏កាន់តែត្រជាក់  និងហ៊ុមព័ទ្ធដោយធម្មជាតិដ៏សម្បូរបែប ម្ហូបឆ្ងាញ់ៗ ភ្នំព្រិល កន្លែងកំសាន្ដនានា និងផ្កាសាគូរ៉ាដ៏ស្រស់ស្អាត ខេត្តនេះទាក់ទាញភ្ញៀវទេសចរជាច្រើនជារៀងរាល់ឆ្នាំ។</p>
            <p>ដោយសារការរងផលពិបាកពីអាកាសធាតុ កសិករនៅក្នុងខេត្ត អាអូម៉ូរី ត្រូវបានទទួលការបណ្តុះបណ្តាលយ៉ាងពិសេស ដើម្បីអភិវឌ្ឍបច្ចេកទេសដាំផ្លែប៉ោមជាង 140 ឆ្នាំមកហើយនេះ ដោយបានធ្វើឲ្យផ្លែប៉ោមអាអូម៉ូរី មានឈ្មោះល្បីល្បាយទូទាំងប្រទេស និងពិភពលោក ជាមួយនឹងរសជាតិឆ្ងាញ់ ផ្លែធំៗ មានសម្បូរជីវជាតិចិញ្ចឹមច្រើន។</p>
          </div>
        </div>
        <img v-for="img in img.other" :key="img.id" class="img-s" :src="require(`@/assets/${img.url}`)" :alt="img.alt">
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'AboutAomori',
  props: ['img'],
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

  #about-aomori{
    background-color: $bgc1;
  }
  
  .content-bottom{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 5%;
    row-gap: 50px;

    .img-m{
      float: left;
      margin: 0 3rem 3rem 0;
      width: 40%;
      height: auto;
      object-fit: contain;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    }

    .content-text{
      width: 100%;
      font-size: 20px;
      text-align: left;

    }
    .img-s{
      width: 30%;
      height: 230px;
      object-fit: cover; 
    }
  }

  // media
  @media screen and (max-width: 1000px){
    .content-bottom .row{
      .img-m{
        margin: 0 1rem 1rem 0;
      }
      .content-text{
        width: 100%;
        font-size: 1rem;
      } 
    }
  }
  @media screen and (max-width: 750px){
    .content-bottom{
      flex-direction: column;
      
      .row{
        display: flex;
        flex-wrap: wrap;
        
        .img-m{
          float: none;
          width: 100%;
          margin-bottom: 2rem;
        }
        .content-text{
          width: 100%;
          font-size: 1rem;
        }
      }

      .img-s{
        display: none;
      }
    }
  }

</style>