<template>
  <section class="container-fluid">
    <div class="container-L">
      <h1>ផ្លែប៉ោមអាអូម៉ូរី  ប្រទេសជប៉ុន</h1>
      <div class="content-bottom">
        <div class="content-text">
          <p>ផ្លែប៉ោមអាអូម៉ូរី របស់ប្រទេសជប៉ុនត្រូវបានគេចាត់ទុកថាជាផ្លែប៉ោមដ៏ឆ្ងាញ់បំផុតមួយនៅក្នុងពិភពលោក។</p>
          <p>ផ្លែ ប៉ោម របស់ ជប៉ុន មាន ភាព ល្បីល្បាញ  ទៅលើ គុណភាព ជា យូរ លង់ណាស់មក ហើយ។ ក្នុងចំណោមតំបន់ដែលដាំផ្លែប៉ោមសំខាន់ៗរបស់ប្រទេសជប៉ុន តំបន់ដែលលេចធ្លោជាងគេបំផុតគឺខេត្ត អាអូម៉ូរី  ដែលមានទីតាំងនៅភាគខាងជើងនៃប្រទេសជប៉ុន ។  ខេត្តនេះត្រូវបានគេស្គាល់ថាជាខេត្តកំណើតរបស់ផ្លែប៉ោមជប៉ុនដំបូងគេបង្អស់ដែលមានប្រវត្តិជាង 140 ឆ្នាំមកហើយ។ ជារៀងរាល់ឆ្នាំខេត្ត អាអូម៉ូរី ផ្តល់ផលរហូតដល់ 60% នៃផលិតកម្មផ្លែប៉ោមសម្រាប់ប្រទេសជប៉ុន។ ទិន្នផលសរុបនៅឆ្នាំ 2020 បានកើនឡើងដល់ 463,300 តោន។</p>
          <p>បច្ចុប្បន្ននេះ ខេត្ត អាអូម៉ូរី  មានផ្លែប៉ោមឆ្ងាញ់ចំនួន 50 ពូជ ដែលក្នុងនោះពូជល្បីល្បាញបំផុត និងទទួលបានទិន្នផលខ្ពស់បំផុតគឺប្រភេទពូជ ហ្វឹជិ ដែលមានចំនួនប្រហែល 50%នៃទិន្នផលសរុប ។ ជារៀងរាល់ឆ្នាំ ផ្លែប៉ោមអាអូម៉ូរី តែងតែត្រូវបាននាំចូលប្រទេសវៀតណាមចាប់ពីខែធ្នូហើយលក់រហូតដល់រដូវចូលឆ្នាំថ្មី។  វាមានប្រជាប្រិយភាពយ៉ាងខ្លាំងពីសំណាក់អតិថិជនវៀតណាម ដោយសារវាមានទំហំធំ ពណ៏ស្អាតទាក់ភ្នែក រសជាតិឈ្ងុយឆ្ងាញ់ សាកសមជាកាដូក្នុងឱកាសនិទាឃរដូវ!</p>
          <div class="align-center">
            <router-link to="/aomori-apple#aomori-apple" class="btn">
              អាន បន្ថែម
            </router-link>
          </div>
        </div>
        <img :src="require(`@/assets/${img.url}`)" :alt="img.alt">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AomoriApple',
  props: ['img'],
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
  
  section.container-fluid{
    color: $c2;

  }
  .content-bottom{
    display: flex;
    justify-content: space-between;
    gap: 5%;

    img{
      flex-grow: 0;
      flex-shrink: 0;
      width: 45%;
      height: auto;
      margin-bottom: 8.125rem;
      object-fit: cover;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    }

    .content-text{
      width: 50%;
      font-size: 1.25rem;
      text-align: left;

      li{
        list-style-position: inside;
      }
    }
    .align-center{
      text-align: center;
    
      .btn{
        margin-top: 3rem;
        text-align: center;
      }
    }
  }

  // media
  @media screen and (max-width: 1000px){
    .content-bottom {

      .content-text{
        font-size: 1rem;
      }
    }
  }

  @media screen and (max-width: 750px){
    .content-bottom{
      flex-direction: column-reverse;

      img{
        width: 100%;
        margin-bottom: 2rem;
      }

      .content-text{
        width: 100%;
        font-size: 1rem;
      }
    }
  }

</style>