<template>
  <div class="testimonial-card">
    <div class="card-img">
      <img :src="require(`@/assets/${testimonial.img}`)" :alt="testimonial.name">
    </div>
    <div class="card-content">
      <h4>{{testimonial.name}}</h4>
      <blockquote>{{testimonial.quote}}</blockquote>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TestimonialCard',
  props: ['testimonial'],
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

  .testimonial-card{
    display: flex;
    padding: 10px;
    background-color: $c3;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

    .card-img{
      flex-shrink: 0;
      flex-basis: 40%;

      img{
        width: 100%;
      }
    }

    .card-content{
      padding: 0px 24px;

      h4{
        font-size: 25px;
        margin-bottom: 1.5rem;
      }

      blockquote {
        font-size: 19px;
        line-height: 1.5rem;
      }

      blockquote:before {
        content: open-quote;
      }

      blockquote:after {
        content: close-quote;
      }

      blockquote:before,
      blockquote:after {
        display: inline-block;
        vertical-align: bottom;
        font-size: 50px;
        font-family: auto;
        top: 10px;
        position: relative;
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  // media
  @media screen and (max-width: 1000px){
    .testimonial-card{
      flex-direction: column;

      .card-img{
        height: 200px;
        flex-basis: 0;

        img{
          height: 100%;
          object-fit: cover;
        }
      }
      .card-content{
        margin: 30px 0 30px;

        h4{
          font-size: 1.25rem;
        }
      }
    }
  }

  @media screen and (max-width: 500px){
    .testimonial-card .card-content blockquote{
      font-size: 1.1rem;
    }
  }

</style>