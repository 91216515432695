<template>
  <div class="container-fluid">
    <div class="container-L row">
      <div v-for="link in links" :key="link.id" class="link">
        <router-link :to="link.href">
        <img :src="require(`@/assets/${link.url}`)" :alt="link.alt" class="img-bg">
        <div class="content" >
          <h3>{{link.title}}</h3>
          <div class="btn">អាន បន្ថែម</div>
        </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroLinks',
  props: ['links'],
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

  .container-fluid{
    background-color: $bgc1;
  }

  .row{
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    padding: 50px 0;
  
    .link{
      position: relative;
      width: calc((100% - 180px) / 4);
      text-align: center;

      @media (hover: hover) {
        &:hover{
          box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
          
          .btn{
            transform: scale(1.05);
            transition: 0.2s;
            background-color: $c1-dark;
          }
        }
      }

      img{
        width: 100%;
      }

      .content{
        padding-bottom: 1rem;

        h3{
          margin-top:1rem;
          font-size: 23px;
          text-transform: uppercase;
        }

        .btn{
          margin-top: 2rem;
        }
      }

    }
  }


  // media
  @media screen and (max-width: 1250px){
    .row .link .content h3{
      font-size: 1.3rem;
    }
  }

  @media screen and (max-width: 1100px){
    .row{
      gap: 27px;
      .link {
        width: calc((100% - 81px) / 4);
        .content{
          h3{
            font-size: 1.1rem;
            white-space: nowrap
          }
          .btn{
            font-size: 0.8rem;
            margin-top: 1rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 750px){
    .row{
      justify-content: center;
      column-gap: 110px;
      .link{
        width: calc(100% / 2);
        max-width: 150px;
      } 
    }
  }

  @media screen and (max-width: 500px){
    .row .link{
      width: 100%;
    }
  }


</style>