<template>
  <header>
    <div class="container-fluid">
      <div class="container-XL">
        <router-link to="/">
          <img class="logo" src="@/assets/logo-b.png" alt="logo">
        </router-link>
        <nav class="desktop-nav">
          <ul>
            <li class="hide-m">
              <router-link :to="{ name: 'home'}">ទំព័រដើម</router-link>
            </li>
            <li v-for="link in links" :key="link.id">
              <RouterLink :to="link.href" :class="link.class">{{ link.title }}</RouterLink>
            </li>
          </ul>
        </nav>
        <SocialsNav class="desktop-nav hide-m" />
        <!-- mobile -->
        <div class="mobile-nav hamburger-container" @click="hamburgerOpen = !hamburgerOpen" :class="hamburgerOpen ? 'hamburger--is-open' : ''">
          <div class="mobile-nav hamburger">
            <div class="hamburger__item hamburger__item--first"></div>
            <div class="hamburger__item hamburger__item--middle"></div>
            <div class="hamburger__item hamburger__item--last"></div>
          </div>
        </div>
        <!-- /mobile -->
      </div>
      <!-- mobile -->
      <Transition name="fade">
        <nav class="mobile-nav dropdown" v-show="hamburgerOpen">
          <ul :class="hamburgerOpen ? 'dropdown--is-open' : ''" @click="hamburgerOpen = !hamburgerOpen">
            <li>
              <router-link :to="{ name: 'home'}">ទំព័រដើម</router-link>
            </li>
            <li v-for="link in links" :key="link.id">
              <RouterLink :to="link.href" :class="link.class">{{ link.title }}</RouterLink>
            </li>
            <li>
              <SocialsNav />
            </li>
          </ul>
        </nav>
      </Transition>
      <!-- /mobile -->
    </div>
    </header>
</template>

<script>
import SocialsNav from '@/components/SocialsNav.vue';
import sourceData from '@/data.json';

export default {
  name: 'Header',
  components: {
    SocialsNav,
  },
  data() {
    return{
      hamburgerOpen: false,
      links: sourceData.homeview.links
    }
  },
}
</script>

<style lang='scss' scoped>
@import '@/scss/_variables.scss';

  .container-fluid{
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    background-color: $c3;
  }
  .container-XL{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo{
    height: 80px;
  }
  nav ul{
    display: flex;
    justify-content: flex-end;
    align-items: center;

    li {
      list-style: none;
      margin-right: 20px;
      text-transform: uppercase;

      &:last-child{
        margin-right: 0;
      }
      a{
        text-decoration: none;
        font-weight: bold;
        color: $c2;

        &.router-link-exact-active {
          color: $c1;

          &.active-disabled{
            color: $c2;
          }
        }
        @media (hover: hover) {
          &:hover,
          &.router-link-exact-active.active-disabled:hover{
            color: $c1;
          }
        }
      }
    }
  }
  .mobile-nav{
    display: none;
    justify-content: center;
    text-align: center;

    &.hamburger-container{
      padding: 10px;
      cursor: pointer;
    }
    &.dropdown{
      background-color: #eee;
    }
    & ul{
      display: none;
      flex-direction: column;
      opacity: 0;
      transition: opacity 1s ease-out;

      &.dropdown--is-open{
        display: flex;
        opacity: 1;
      }
      li{
        padding: 30px 60px;
        margin: 0;
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .hamburger{
    display: none;
    height: 26px;
    width: 40px;
    flex-direction: column;
    justify-content: space-between;
    
    &:hover {
      cursor: pointer;
    }
    &__item {
      height: 5px;
      width: 100%;
      background: $c1;
      transition: transform 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95),
      opacity 300ms linear;
      
      &--first {
        .hamburger--is-open & {
          transform: translate(0, 11px) rotate(45deg);
        }
      }
      &--middle {
        .hamburger--is-open & {
          opacity: 0;
        }
      }
      &--last {
        .hamburger--is-open & {
          transform: translate(0, -11px) rotate(-45deg);
        }
      }
    }
  }

// media
  @media screen and (max-width: 1300px){
    .logo{
      height: 60px;
    }
  }
  @media screen and (max-width: 1000px){
    .hide-m{
      display: none;
    }
  }

  @media screen and (max-width: 750px){
    .mobile-nav{
      display: flex;
    }
    .desktop-nav{
      display: none;
    }
    .logo{
      height: 60px;
    }
  }

</style>